import PropTypes from "prop-types";
import React from "react";

import { Grid, Cell } from "../../components/ui";
import {
  ContentObject,
  FormattedContentItem,
  ContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import {
  HeadingLarge,
  LabelSmall,
  ParagraphMedium,
  LabelMedium,
  HeadingMedium,
} from "baseui/typography";
import { Block } from "baseui/block";

import stars from "../../assets/images/stars.svg";
import clutchLogo from "../../assets/images/clutch-logo.svg";
import clutchLogoWhite from "../../assets/images/clutch-logo-white.svg";

import { useTheme } from "../../contexts/theme";

const Reviews = ({ slug }) => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return ContentObject.CaseStudies.singleCaseStudies[slug].reviews ? (
    <article data-csweb={`case-study-${slug}-reviews`}>
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale1200,
              paddingBottom: theme.sizing.scale2400,
              position: "relative",
              zIndex: "2",
              [theme.breakpoints.mediaQueries.medium]: {
                paddingBottom: theme.sizing.scale1200,
              },
            })}
          >
            <LabelSmall
              $style={{
                textAlign: "center",
                textTransform: "uppercase",
                letterSpacing: "3px",
              }}
            >
              <FormattedContentItem
                scope="CaseStudies"
                accessor={`singleCaseStudies.${slug}.reviews.subtitle`}
              />
            </LabelSmall>
            <HeadingLarge
              $style={{ textAlign: "center" }}
              marginBottom="scale100"
              marginTop="scale500"
            >
              <FormattedContentItem
                scope="CaseStudies"
                accessor={`singleCaseStudies.${slug}.reviews.title`}
              />
            </HeadingLarge>
            <div
              className={css({
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1200,
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                position: "relative",
                justifyContent: "center",
                [theme.breakpoints.mediaQueries.medium]: {
                  flexDirection: "column",
                },
              })}
            >
              {ContentObject.CaseStudies.singleCaseStudies[
                slug
              ].reviews.items.map((item, index) => {
                if (
                  ContentObject.CaseStudies.singleCaseStudies[slug].reviews
                    .items.length === 1
                ) {
                  return (
                    <Block
                      $as={({ children, ...props }) => (
                        <a
                          href={
                            ContentObject.CaseStudies.singleCaseStudies[slug]
                              .reviews.items[index].url
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          {...props}
                        >
                          {children}
                        </a>
                      )}
                      key={index}
                      overrides={{
                        Block: {
                          style: {
                            maxWidth: "80%",
                            paddingLeft: theme.sizing.scale900,
                            paddingRight: theme.sizing.scale900,
                            paddingTop: theme.sizing.scale1000,
                            paddingBottom: theme.sizing.scale1000,
                            boxSizing: "border-box",
                            position: "relative",
                            marginTop: theme.sizing.scale1000,
                            marginLeft: theme.sizing.scale1000,
                            marginRight: theme.sizing.scale1000,
                            display: "flex",
                            flexBasis: "0",
                            flexGrow: "1",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            textDecoration: "none",
                            transitionProperty: "all",
                            transitionDuration: "200ms",
                            transitionTimingFunction: "ease-in-out",
                            ":hover [data-csweb=ui-review-logo]": {
                              opacity: "1",
                            },
                            ":hover [data-csweb=ui-review-stars]": {
                              filter:
                                "brightness(0) saturate(100%) invert(12%) sepia(99%) saturate(7032%) hue-rotate(262deg) brightness(78%) contrast(129%)",
                            },
                            [theme.breakpoints.mediaQueries.medium]: {
                              width: "100%",
                              maxWidth: "unset",
                            },
                          },
                        },
                      }}
                    >
                      <div
                        className={css({
                          alignSelf: "stretch",
                          position: "relative",
                        })}
                      >
                        <img
                          alt="Review stars"
                          data-csweb="ui-review-stars"
                          src={stars}
                          height="18px"
                          className={css({
                            position: "relative",
                            left: "50%",
                            transform: "translateX(-50%)",
                            transition:
                              "all 300ms ease-in-out, filter 0ms ease",
                            filter:
                              "brightness(0) saturate(100%) invert(12%) sepia(99%) saturate(7032%) hue-rotate(262deg) brightness(78%) contrast(129%)",
                          })}
                        />
                      </div>
                      <HeadingMedium
                        $style={{
                          marginTop: theme.sizing.scale1600,
                          marginBottom: theme.sizing.scale200,
                          textAlign: "left",
                        }}
                      >
                        <ContentItem
                          scope="CaseStudies"
                          accessor={`singleCaseStudies.${slug}.reviews.items.${index}.title`}
                        />
                      </HeadingMedium>
                      <ParagraphMedium
                        marginTop="scale1000"
                        marginBottom="scale0"
                      >
                        <FormattedContentItem
                          scope="CaseStudies"
                          accessor={`singleCaseStudies.${slug}.reviews.items.${index}.text`}
                        />
                      </ParagraphMedium>
                      <div
                        data-csweb="review-footer"
                        className={css({
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          marginTop: theme.sizing.scale1000,
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionTimingFunction: "ease-in-out",
                          [theme.breakpoints.mediaQueries.small]: {
                            flexDirection: "column",
                            paddingRight: theme.sizing.scale400,
                          },
                        })}
                      >
                        <div
                          className={css({
                            [theme.breakpoints.mediaQueries.small]: {
                              order: "1",
                              marginTop: theme.sizing.scale800,
                              marginBottom: theme.sizing.scale600,
                            },
                          })}
                        >
                          {item.isClutch && (
                            <a
                              href={
                                ContentObject.CaseStudies.singleCaseStudies[
                                  slug
                                ].reviews.items[index].url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                alt="Clutch"
                                src={
                                  useDarkTheme ? clutchLogoWhite : clutchLogo
                                }
                                height="20px"
                              />
                              <LabelSmall
                                data-csweb="typo-label"
                                $style={{
                                  marginLeft: theme.sizing.scale500,
                                  textTransform: "uppercase",
                                  fontWeight: "400",
                                  transitionProperty: "all",
                                  transitionDuration: "200ms",
                                  transitionTimingFunction: "ease-in-out",
                                  display: "inline-block",
                                  letterSpacing: "1.5px",
                                  [theme.breakpoints.mediaQueries.small]: {
                                    display: "none",
                                  },
                                }}
                              >
                                <FormattedContentItem
                                  scope="Home"
                                  accessor={`testimonials.readFullReview`}
                                />
                              </LabelSmall>
                            </a>
                          )}
                        </div>
                        <div
                          className={css({
                            [theme.breakpoints.mediaQueries.small]: {
                              alignSelf: "flex-end",
                            },
                          })}
                        >
                          <LabelMedium
                            color="primary"
                            $style={{
                              letterSpacing: "1.5px",
                              fontWeight: "700",
                              textAlign: "right",
                            }}
                          >
                            <ContentItem
                              scope="CaseStudies"
                              accessor={`singleCaseStudies.${slug}.reviews.items.${index}.name`}
                            />
                          </LabelMedium>
                          <LabelSmall
                            data-csweb="typo-label"
                            $style={{
                              marginTop: theme.sizing.scale200,
                              textTransform: "uppercase",
                              letterSpacing: "2px",
                              fontWeight: "400",
                              transitionProperty: "all",
                              transitionDuration: "200ms",
                              transitionTimingFunction: "ease-in-out",
                              textAlign: "right",
                            }}
                          >
                            <ContentItem
                              scope="CaseStudies"
                              accessor={`singleCaseStudies.${slug}.reviews.items.${index}.job`}
                            />
                          </LabelSmall>
                        </div>
                      </div>
                    </Block>
                  );
                } else {
                  return (
                    <Block
                      $as={({ children, ...props }) => (
                        <a
                          href={
                            ContentObject.CaseStudies.singleCaseStudies[slug]
                              .reviews.items[index].url
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          {...props}
                        >
                          {children}
                        </a>
                      )}
                      key={index}
                      overrides={{
                        Block: {
                          style: {
                            borderTopWidth: "1px",
                            borderRightWidth: "1px",
                            borderBottomWidth: "1px",
                            borderLeftWidth: "1px",
                            borderTopStyle: "solid",
                            borderRightStyle: "solid",
                            borderBottomStyle: "solid",
                            borderLeftStyle: "solid",
                            borderTopColor: theme.colors.backgroundLightAccent,
                            borderRightColor:
                              theme.colors.backgroundLightAccent,
                            borderBottomColor:
                              theme.colors.backgroundLightAccent,
                            borderLeftColor: theme.colors.backgroundLightAccent,
                            backgroundColor: theme.colors.backgroundPrimary,
                            paddingLeft: theme.sizing.scale900,
                            paddingRight: theme.sizing.scale900,
                            paddingTop: theme.sizing.scale1000,
                            paddingBottom: theme.sizing.scale1000,
                            boxSizing: "border-box",
                            position: "relative",
                            marginTop: theme.sizing.scale1000,
                            marginLeft: theme.sizing.scale1000,
                            marginRight: theme.sizing.scale1000,
                            display: "flex",
                            flexGrow: "1",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "center",
                            textDecoration: "none",
                            transitionProperty: "all",
                            transitionDuration: "200ms",
                            transitionTimingFunction: "ease-in-out",
                            ":hover": {
                              boxShadow: `0px 0px 50px 0px ${theme.colors.servicesCardGlow}`,
                              cursor: "pointer",
                            },
                            ":hover [data-csweb=ui-review-logo]": {
                              opacity: "1",
                            },
                            ":hover [data-csweb=ui-review-stars]": {
                              left: "0%",
                              transform: "translateX(0%)",
                              filter:
                                "brightness(0) saturate(100%) invert(12%) sepia(99%) saturate(7032%) hue-rotate(262deg) brightness(78%) contrast(129%)",
                            },
                            [theme.breakpoints.mediaQueries.medium]: {
                              width: "100%",
                            },
                          },
                        },
                      }}
                    >
                      <div
                        className={css({
                          alignSelf: "stretch",
                          position: "relative",
                        })}
                      >
                        <img
                          alt="Review stars"
                          data-csweb="ui-review-stars"
                          src={stars}
                          height="24px"
                          className={css({
                            position: "relative",
                            left: "50%",
                            transform: "translateX(-50%)",
                            transition:
                              "all 300ms ease-in-out, filter 0ms ease",
                            ...(item.isClutch &&
                              index === 0 && {
                                left: "0%",
                                transform: "translateX(0%)",
                                filter:
                                  "brightness(0) saturate(100%) invert(12%) sepia(99%) saturate(7032%) hue-rotate(262deg) brightness(78%) contrast(129%)",
                              }),
                          })}
                        />
                        {item.isClutch && (
                          <img
                            alt="Clutch"
                            data-csweb="ui-review-logo"
                            src={useDarkTheme ? clutchLogoWhite : clutchLogo}
                            height="24px"
                            className={css({
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                              transitionProperty: "all",
                              transitionDuration: "300ms",
                              transitionTimingFunction: "ease-in-out",
                              opacity: "0",
                              ...(index === 0 && { opacity: "1" }),
                            })}
                          />
                        )}
                      </div>
                      <ParagraphMedium
                        marginTop="scale1000"
                        marginBottom="scale0"
                      >
                        <FormattedContentItem
                          scope="CaseStudies"
                          accessor={`singleCaseStudies.${slug}.reviews.items.${index}.text`}
                        />
                      </ParagraphMedium>
                      <div
                        className={css({
                          alignSelf: "flex-end",
                          textAlign: "right",
                          marginTop: theme.sizing.scale800,
                        })}
                      >
                        <LabelMedium
                          color="primary"
                          $style={{
                            letterSpacing: "1.5px",
                            fontWeight: "700",
                          }}
                        >
                          <ContentItem
                            scope="CaseStudies"
                            accessor={`singleCaseStudies.${slug}.reviews.items.${index}.name`}
                          />
                        </LabelMedium>
                        <LabelSmall
                          data-csweb="typo-label"
                          $style={{
                            marginTop: theme.sizing.scale100,
                            marginBottom: "0px",
                            textTransform: "uppercase",
                            letterSpacing: "1.5px",
                            fontWeight: "400",
                            transitionProperty: "all",
                            transitionDuration: "200ms",
                            transitionTimingFunction: "ease-in-out",
                          }}
                        >
                          <ContentItem
                            scope="CaseStudies"
                            accessor={`singleCaseStudies.${slug}.reviews.items.${index}.job`}
                          />
                        </LabelSmall>
                      </div>
                    </Block>
                  );
                }
              })}
            </div>
          </div>
        </Cell>
        <Cell span={[4, 6, 7]}>
          <div
            data-csweb="bg-graybox"
            className={css({
              position: "relative",
              transitionProperty: "all",
              transitionDuration: "200ms",
              transitionTimingFunction: "ease-in-out",
              width: "100%",
              height: "260px",
              marginTop: "-390px",
              zIndex: "0",
              opacity: "0.3",
              backgroundColor: theme.colors.backgroundTertiary,
              [theme.breakpoints.mediaQueries.medium]: {
                marginTop: "-160px",
              },
            })}
          ></div>
        </Cell>
      </Grid>
    </article>
  ) : null;
};

Reviews.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Reviews;
