import PropTypes from "prop-types";
import React, { createElement } from "react";

import { Grid, Cell } from "../../components/ui";
import {
  ContentItem,
  FormattedContentItem,
  ContentObject,
} from "../../components/content";

import { useStyletron } from "baseui";
import { HeadingMedium, ParagraphMedium } from "baseui/typography";

import Icons from "../../assets/images/icons";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";
import { useTheme } from "../../contexts/theme";

import VisibilitySensor from "react-visibility-sensor";

const Attributes = ({ slug }) => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article
      data-csweb={`case-study-${slug}-attributes`}
      className={css({
        backgroundColor: theme.colors.blue30,
      })}
    >
      {ContentObject.CaseStudies.singleCaseStudies[slug].attributes.map(
        (item, index) => (
          <div
            key={index}
            className={css({
              width: "100%",
              position: "relative",
              ...(index % 2 !== 0
                ? {
                    marginTop: theme.sizing.scale900,
                    marginBottom: theme.sizing.scale900,
                    paddingTop: theme.sizing.scale1600,
                    paddingBottom: theme.sizing.scale1600,
                    backgroundColor: theme.colors.backgroundPrimary,
                    [theme.breakpoints.mediaQueries.large]: {
                      paddingTop: theme.sizing.scale1200,
                      paddingBottom: theme.sizing.scale1200,
                      marginTop: theme.sizing.scale0,
                      marginBottom: theme.sizing.scale0,
                    },
                  }
                : {
                    paddingTop: theme.sizing.scale2400,
                    paddingBottom: theme.sizing.scale2400,
                    backgroundColor: theme.colors.backgroundTertiary,
                    [theme.breakpoints.mediaQueries.large]: {
                      paddingTop: theme.sizing.scale1200,
                      paddingBottom: theme.sizing.scale1200,
                    },
                  }),
            })}
          >
            <Grid>
              {index % 2 !== 0 && [
                <Cell key={`${index}11`} span={[3, 6, 9]}>
                  <div
                    className={css({
                      position: "relative",
                    })}
                  >
                    <div
                      className={css({
                        position: "absolute",
                        zIndex: "0",
                        top: `-${theme.sizing.scale2400}`,
                        right: "-18px",
                        height: "5vh",
                        width: "80vw",
                        backgroundColor: theme.colors.backgroundPrimary,
                        borderTopRightRadius: "30px",
                        [theme.breakpoints.mediaQueries.large]: {
                          top: "0px",
                          display: "none",
                        },
                      })}
                    ></div>
                  </div>
                </Cell>,
                <Cell key={`${index}12`} span={[1, 2, 3]}>
                  <div
                    className={css({
                      position: "relative",
                    })}
                  >
                    <div
                      className={css({
                        position: "absolute",
                        zIndex: "0",
                        top: `-${theme.sizing.scale2400}`,
                        left: "-18px",
                        height: "5vh",
                        width: "50vw",
                        backgroundColor: theme.colors.backgroundPrimary,
                        borderTopLeftRadius: "30px",
                        [theme.breakpoints.mediaQueries.large]: {
                          top: "0px",
                          display: "none",
                        },
                      })}
                    ></div>
                  </div>
                </Cell>,
              ]}
              {ContentObject.CaseStudies.singleCaseStudies[slug].attributes[
                index
              ].description && (
                <Cell
                  skip={[0, 0, 1]}
                  span={
                    ContentObject.CaseStudies.singleCaseStudies[slug]
                      .attributes[index].list
                      ? [2, 5, 5]
                      : [5, 8, 8]
                  }
                  order={[2, 2, 0]}
                >
                  <div className={css({ position: "relative" })}>
                    <HeadingMedium marginTop="scale0" marginBottom="scale800">
                      <ContentItem
                        scope="CaseStudies"
                        accessor={`singleCaseStudies.${slug}.attributes.${index}.title`}
                      />
                    </HeadingMedium>
                    <ParagraphMedium
                      marginTop="scale0"
                      marginBottom="scale0"
                      $style={{ lineHeight: "1.6" }}
                    >
                      <FormattedContentItem
                        scope="CaseStudies"
                        accessor={`singleCaseStudies.${slug}.attributes.${index}.description`}
                      />
                    </ParagraphMedium>
                  </div>
                </Cell>
              )}
              {ContentObject.CaseStudies.singleCaseStudies[slug].attributes[
                index
              ].list && (
                <Cell
                  skip={
                    ContentObject.CaseStudies.singleCaseStudies[slug]
                      .attributes[index].description
                      ? [0]
                      : [0, 0, 1]
                  }
                  span={
                    ContentObject.CaseStudies.singleCaseStudies[slug]
                      .attributes[index].description
                      ? [2, 3, 3]
                      : [6, 8, 8]
                  }
                  align={["center", "flex-end"]}
                  order={[3, 3, 0]}
                >
                  {!ContentObject.CaseStudies.singleCaseStudies[slug]
                    .attributes[index].description && (
                    <HeadingMedium marginTop="scale0" marginBottom="scale800">
                      <ContentItem
                        scope="CaseStudies"
                        accessor={`singleCaseStudies.${slug}.attributes.${index}.title`}
                      />
                    </HeadingMedium>
                  )}
                  <ul
                    className={css({
                      listStyle: "none",
                      marginBottom: "0px",
                      position: "relative",
                      paddingLeft: "0px",
                      [theme.breakpoints.mediaQueries.medium]: {
                        textAlign: "left",
                        paddingLeft: "0px",
                        marginTop: theme.sizing.scale800,
                      },
                    })}
                  >
                    {ContentObject.CaseStudies.singleCaseStudies[
                      slug
                    ].attributes[index].list.map((listItem, index_) => (
                      <li
                        key={index_}
                        className={css({
                          marginTop: theme.sizing.scale200,
                          marginBottom: theme.sizing.scale200,
                          fontSize: "17.8px",
                          fontWeight: "600",
                          color: theme.colors.contentPrimary,
                          textTransform: "uppercase",
                          "::before": {
                            content: "'\\2022'",
                            marginRight: theme.sizing.scale300,
                            fontSize: "18px",
                            lineHeight: "16px",
                            color: theme.colors.primary,
                          },
                        })}
                      >
                        <ContentItem
                          scope="CaseStudies"
                          accessor={`singleCaseStudies.${slug}.attributes.${index}.list.${index_}`}
                        />
                      </li>
                    ))}
                  </ul>
                </Cell>
              )}
              <Cell span={[4, 8, 2]} align="flex-end" order={[0, 0, 0]}>
                <VisibilitySensor
                  partialVisibility
                  offset={{
                    top: -10000,
                    bottom:
                      typeof window !== "undefined" && window
                        ? window.innerHeight / 6
                        : 0,
                  }}
                >
                  {({ isVisible }) => (
                    <>
                      {createElement(Icons[item.icon], {
                        className: css({
                          maxWidth: "170px",
                          height: "140px",
                          float: "right",
                          strokeDashoffset: "1500",
                          strokeDasharray: "1500",
                          animationDuration:
                            item.icon === "Handshake" ? "5000ms" : "2500ms",
                          animationFillMode: "both",
                          animationTimingFunction: "linear",
                          animationIterationCount: "1",
                          animationName: isVisible
                            ? {
                                from: {
                                  strokeDashoffset: "1500",
                                },
                                to: {
                                  strokeDashoffset: "0",
                                },
                              }
                            : {},
                          [theme.breakpoints.mediaQueries.large]: {
                            float: "none",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: "100px",
                            marginBottom: theme.sizing.scale800,
                          },
                        }),
                      })}
                    </>
                  )}
                </VisibilitySensor>
              </Cell>
              {index % 2 !== 0 && [
                <Cell key={`${index}21`} span={[3, 6, 9]} order={[1, 1, 0]}>
                  <div
                    className={css({
                      position: "relative",
                    })}
                  >
                    <div
                      className={css({
                        position: "absolute",
                        zIndex: "0",
                        right: "-18px",
                        bottom: `-${theme.sizing.scale2400}`,
                        height: "5vh",
                        width: "80vw",
                        backgroundColor: theme.colors.backgroundPrimary,
                        borderBottomRightRadius: "30px",
                        [theme.breakpoints.mediaQueries.large]: {
                          bottom: `-${theme.sizing.scale600}`,
                          display: "none",
                        },
                      })}
                    ></div>
                  </div>
                </Cell>,
                <Cell key={`${index}22`} span={[1, 2, 3]}>
                  <img
                    alt="Dot grid background"
                    className={css({
                      position: "absolute",
                      marginTop: "-240px",
                      marginLeft: "-200px",
                      zIndex: "0",
                      pointerEvents: "none",
                    })}
                    src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
                  />
                  <div
                    className={css({
                      position: "relative",
                    })}
                  >
                    <div
                      className={css({
                        position: "absolute",
                        zIndex: "0",
                        left: "-18px",
                        bottom: `-${theme.sizing.scale2400}`,
                        height: "5vh",
                        width: "50vw",
                        backgroundColor: theme.colors.backgroundPrimary,
                        borderBottomLeftRadius: "30px",
                        [theme.breakpoints.mediaQueries.large]: {
                          bottom: `-${theme.sizing.scale600}`,
                          display: "none",
                        },
                      })}
                    ></div>
                  </div>
                </Cell>,
              ]}
            </Grid>
          </div>
        )
      )}
    </article>
  );
};

Attributes.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Attributes;
