import PropTypes from "prop-types";
import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Hero from "./case-study-hero";
import Attributes from "./case-study-attributes";
import Mockups from "./case-study-mockups";
import Technologies from "./case-study-technologies";
import Reviews from "./case-study-reviews";
import Link from "./case-study-link";

const CaseStudy = ({ location }) => {
  const slug = location.pathname.split(`/`)[2];

  return (
    <>
      <SEO
        title={ContentObject.CaseStudies.singleCaseStudies[slug].name}
        description={ContentObject.CaseStudies.singleCaseStudies[slug].caption}
      />

      <Layout
        header={{ position: "absolute", color: "white" }}
        footer={{ size: "compact" }}
      >
        <section data-csweb={`case-study-${slug}`}>
          <Hero slug={slug} />
          <Attributes slug={slug} />
          <Mockups slug={slug} />
          <Technologies slug={slug} />
          <Reviews slug={slug} />
          <Link slug={slug} />
        </section>
      </Layout>
    </>
  );
};

CaseStudy.propTypes = {
  location: PropTypes.object,
};

export default CaseStudy;
