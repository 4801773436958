import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";

import { Grid, Cell, Button, Img } from "../../components/ui";
import {
  ContentItem,
  FormattedContentItem,
  ContentObject,
} from "../../components/content";

import { useStyletron } from "baseui";
import {
  HeadingMedium,
  ParagraphMedium,
  DisplayMedium,
  LabelSmall,
  HeadingLarge,
} from "baseui/typography";

import { AspectRatioBox, AspectRatioBoxBody } from "baseui/aspect-ratio-box";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";
import chevronLeft from "../../assets/images/chevron-left-gray.svg";
import shine from "../../assets/images/shine.svg";
import Masks from "../../assets/images/masks";
import SvgImages from "../../assets/images/svg-images";

import { useTheme } from "../../contexts/theme";

import { Spring } from "react-spring/renderprops";

const MockupsItem = ({ slug, index }) => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();
  const maskRef = useRef(null);
  const [maskHeight, setMaskHeight] = useState(800);

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    if (maskRef && maskRef.current && maskRef.current.complete)
      setMaskHeight(maskRef.current.height);
  }, [maskRef]);

  const imagesCount =
    ContentObject.CaseStudies.singleCaseStudies[slug].mockups[index].items
      .length;

  let Motus;

  if (typeof window !== "undefined" && window) Motus = require("motus");

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (Motus && Motus.default && Motus.default.Animation) {
        document
          .querySelectorAll("[data-csweb=case-study-set]")
          .forEach(value => {
            Motus.default.addAnimation(
              new Motus.default.Animation({
                startPoint: value.offsetTop - window.innerHeight / 2,
                endPoint: value.offsetTop + window.innerHeight / 2,
                $el: value.querySelector("[data-csweb=case-study-title]"),
                keyframes: [
                  { translateY: [`-50px`] },
                  { translateY: [`50px`] },
                ],
              })
            );
          });
      }
    }
  }, []);

  useEffect(
    () => () => {
      Motus.default.clearAnimations();
    },
    []
  );

  if (
    ContentObject.CaseStudies.singleCaseStudies[slug].mockups[index].layout !==
    "masked"
  )
    return (
      <div
        key={index}
        data-csweb="case-study-set"
        className={css({
          width: "100%",
          position: "relative",
          paddingTop: theme.sizing.scale2400,
          paddingBottom: theme.sizing.scale3200,
          backgroundColor:
            index % 2 === 0
              ? theme.colors.backgroundSecondary
              : theme.colors.backgroundPrimary,
          [theme.breakpoints.mediaQueries.medium]: {
            paddingTop: theme.sizing.scale1200,
            paddingBottom: theme.sizing.scale1200,
          },
        })}
      >
        {index % 2 !== 0 && (
          <img
            alt="Dot grid background"
            className={css({
              position: "absolute",
              marginTop: "-240px",
              marginLeft: "200px",
              zIndex: "0",
              pointerEvents: "none",
            })}
            src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
          />
        )}
        {imagesCount > 1 && (
          <Button
            isControl
            kind="circle"
            direction="left"
            className={css({
              //FIXME: sprawdzić pozycję
              position: "absolute !important",
              left: "50px",
              transform: "translate(-50%, -50%)",
              top: "45%",
              zIndex: "2",
              [theme.breakpoints.mediaQueries.small]: {
                marginTop: theme.sizing.scale2400,
                top: "44%",
                left: "32px",
              },
            })}
            onClick={() =>
              setCurrentImage(
                currentImage - 1 < 0 ? imagesCount - 1 : currentImage - 1
              )
            }
          >
            <img alt="More" src={chevronLeft} height="14px" />
          </Button>
        )}
        {imagesCount > 1 && (
          <Button
            isControl
            kind="circle"
            className={css({
              position: "absolute !important",
              right: "50px",
              transform: "translate(50%, -50%)",
              top: "45%",
              zIndex: "2",
              [theme.breakpoints.mediaQueries.small]: {
                marginTop: theme.sizing.scale2400,
                top: "44%",
                right: "32px",
              },
            })}
            onClick={() =>
              setCurrentImage(
                currentImage + 1 > imagesCount - 1 ? 0 : currentImage + 1
              )
            }
          >
            <img
              alt="More"
              src={chevronLeft}
              className={css({ transform: "rotate(180deg)" })}
              height="14px"
            />
          </Button>
        )}
        <Grid>
          <Cell
            order={0}
            skip={[0, 0, index % 2 !== 0 ? 1 : 7]}
            span={[4, 8, index % 2 !== 0 ? 10 : 5]}
          >
            <div className={css({ position: "relative" })}>
              <DisplayMedium
                marginTop="scale1200"
                marginBottom="scale1200"
                color="backgroundLightAccent"
                $style={{
                  transform: "translateY(-50px)",
                  [theme.breakpoints.mediaQueries.small]: {
                    ...theme.typography.DisplaySmall,
                  },
                }}
                data-csweb="case-study-title"
              >
                <ContentItem
                  scope="CaseStudies"
                  accessor={`singleCaseStudies.${slug}.mockups.${index}.title`}
                />
              </DisplayMedium>
            </div>
          </Cell>
        </Grid>
        <Spring
          key={currentImage}
          config={{ tension: 120, friction: 14, duration: 200 }}
          from={{
            opacity: 0.5,
            left: index % 2 !== 0 ? -50 : 50,
          }}
          to={{ opacity: 1, left: 0 }}
        >
          {({ left, ...style }) => {
            return (
              <div
                data-csweb="spring-wrapper"
                className={css({
                  transform: `translateX(${left}px)`,
                  ...style,
                })}
              >
                <Grid>
                  <Cell
                    skip={[0, 0, index % 2 !== 0 ? 2 : 0]}
                    span={[4, 8, 9]}
                    order={2}
                  >
                    <div
                      className={css({
                        display: "flex",
                        justifyContent:
                          index % 2 !== 0 ? "flex-start" : "flex-end",
                        width: "100%",
                        height: "100%",
                      })}
                    >
                      <div
                        data-csweb="box-gradient"
                        className={css({
                          alignSelf: "flex-start",
                          position: "relative",
                          top: `50%`,
                          height: "50%",
                          flexGrow: "1",
                          flexShrink: "0",
                          minWidth: "10%",
                          background:
                            "linear-gradient(0deg, rgba(0,220,230,1) 0%, rgba(60,0,220,1) 100%)",
                          order: index % 2 !== 0 ? 0 : 2,
                          [theme.breakpoints.mediaQueries.small]: {
                            minWidth: "20px",
                          },
                        })}
                      ></div>
                      {ContentObject.CaseStudies.singleCaseStudies[slug]
                        .mockups[index].items[currentImage].image && (
                        <AspectRatioBox
                          aspectRatio={
                            ContentObject.CaseStudies.singleCaseStudies[slug]
                              .mockups[index].width /
                              ContentObject.CaseStudies.singleCaseStudies[slug]
                                .mockups[index].height || 16 / 9
                          }
                          width={"100%"}
                          overflow="hidden"
                          $style={{
                            borderTopStyle: "solid",
                            borderRightStyle: "solid",
                            borderBottomStyle: "solid",
                            borderLeftStyle: "solid",
                            borderTopWidth: "1px",
                            borderRightWidth: "1px",
                            borderBottomWidth: "1px",
                            borderLeftWidth: "1px",
                            borderTopColor: theme.colors.blue30,
                            borderRightColor: theme.colors.blue30,
                            borderBottomColor: theme.colors.blue30,
                            borderLeftColor: theme.colors.blue30,
                            overflow: "hidden",
                          }}
                        >
                          {ContentObject.CaseStudies.singleCaseStudies[slug]
                            .mockups[index].items[currentImage].isSvg ? (
                            <AspectRatioBoxBody
                              as="img"
                              src={
                                SvgImages[
                                  ContentObject.CaseStudies.singleCaseStudies[
                                    slug
                                  ].mockups[index].items[currentImage].image
                                ]
                              }
                            />
                          ) : (
                            <AspectRatioBoxBody
                              as={() => (
                                <Img
                                  src={`case-studies/${slug}/${ContentObject.CaseStudies.singleCaseStudies[slug].mockups[index].items[currentImage].image}`}
                                  objectPosition={
                                    ContentObject.CaseStudies.singleCaseStudies[
                                      slug
                                    ].mockups[index].objectPosition || "left"
                                  }
                                />
                              )}
                            />
                          )}
                        </AspectRatioBox>
                      )}
                    </div>
                  </Cell>
                  <Cell span={1} order={index % 2 !== 0 ? 3 : 1}>
                    <div
                      className={css({
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      })}
                    >
                      <div
                        data-csweb="bg-graybox"
                        className={css({
                          position: "absolute",
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionTimingFunction: "ease-in-out",
                          width: "100vw",
                          height: "100%",
                          zIndex: "0",
                          backgroundColor: theme.colors.blue30,
                          ...(index % 2 !== 0
                            ? {
                                left: "0px",
                              }
                            : { right: "0px" }),
                        })}
                      ></div>
                    </div>
                  </Cell>
                  <Cell
                    skip={[0, 0, index % 2 !== 0 ? 2 : 4]}
                    span={[4, 8, 6]}
                    order={4}
                  >
                    <div
                      className={css({
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: theme.sizing.scale1200,
                        [theme.breakpoints.mediaQueries.small]: {
                          marginTop: theme.sizing.scale2400,
                        },
                      })}
                    >
                      <div
                        className={css({
                          width: "140px",
                          flexShrink: "0",
                          order: index % 2 !== 0 ? 0 : 2,
                          [theme.breakpoints.mediaQueries.small]: {
                            width: "50px",
                          },
                        })}
                      >
                        {imagesCount > 1 && (
                          <LabelSmall
                            $style={{
                              marginTop: theme.sizing.scale550,
                              textTransform: "uppercase",
                              letterSpacing: "1px",
                              transitionProperty: "all",
                              transitionDuration: "200ms",
                              transitionTimingFunction: "ease-in-out",
                              textAlign: index % 2 !== 0 ? "left" : "right",
                            }}
                          >
                            0{currentImage + 1}.
                          </LabelSmall>
                        )}
                      </div>
                      <div className={css({ width: "100%" })}>
                        <HeadingMedium
                          marginTop="scale0"
                          marginBottom="scale800"
                        >
                          <ContentItem
                            scope="CaseStudies"
                            accessor={`singleCaseStudies.${slug}.mockups.${index}.items.${currentImage}.title`}
                          />
                        </HeadingMedium>
                        <ParagraphMedium
                          marginTop="scale0"
                          marginBottom="scale0"
                          $style={{ lineHeight: "1.6" }}
                        >
                          <FormattedContentItem
                            scope="CaseStudies"
                            accessor={`singleCaseStudies.${slug}.mockups.${index}.items.${currentImage}.description`}
                          />
                        </ParagraphMedium>
                      </div>
                    </div>
                  </Cell>
                </Grid>
              </div>
            );
          }}
        </Spring>
        {index % 2 !== 0 && (
          <img
            alt="Dot grid background"
            className={css({
              position: "absolute",
              marginTop: "-100px",
              marginLeft: "500px",
              zIndex: "0",
              pointerEvents: "none",
            })}
            src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
          />
        )}
      </div>
    );
  else
    return (
      <div
        key={index}
        className={css({
          width: "100%",
          position: "relative",
          height: "80vh",
          marginTop: theme.sizing.scale900,
          marginBottom: theme.sizing.scale900,
          backgroundColor:
            index % 2 === 0
              ? theme.colors.backgroundSecondary
              : theme.colors.backgroundPrimary,
        })}
      >
        {imagesCount > 1 && (
          <Button
            isControl
            kind="circle"
            direction="left"
            className={css({
              //FIXME: sprawdzić pozycję
              position: "absolute !important",
              right: "80px",
              transform: "translate(-50%, -50%)",
              bottom: "20px",
              zIndex: "5",
              [theme.breakpoints.mediaQueries.medium]: {
                left: "30px",
                top: "60%",
                right: "unset",
              },
            })}
            onClick={() =>
              setCurrentImage(
                currentImage - 1 < 0 ? imagesCount - 1 : currentImage - 1
              )
            }
          >
            <img alt="More" src={chevronLeft} height="14px" />
          </Button>
        )}
        {imagesCount > 1 && (
          <Button
            isControl
            kind="circle"
            className={css({
              position: "absolute !important",
              right: "60px",
              transform: "translate(50%, -50%)",
              bottom: "20px",
              zIndex: "5",
              [theme.breakpoints.mediaQueries.medium]: {
                right: "30px",
                top: "60%",
              },
            })}
            onClick={() =>
              setCurrentImage(
                currentImage + 1 > imagesCount - 1 ? 0 : currentImage + 1
              )
            }
          >
            <img
              alt="More"
              src={chevronLeft}
              className={css({ transform: "rotate(180deg)" })}
              height="14px"
            />
          </Button>
        )}
        <div
          className={css({
            position: "absolute",
            display: "flex",
            left: "0px",
            top: `-${theme.sizing.scale100}`,
            bottom: "0px",
            width: "100%",
            zIndex: "0",
            flexDirection: "column",
            justifyContent: "space-between",
          })}
        >
          <div
            className={css({
              width: "30%",
              background: `linear-gradient(90deg, ${theme.colors.backgroundSecondary} 0%, ${theme.colors.backgroundLightAccent} 100%)`,
              opacity: "0.5",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              order: "1",
            })}
          ></div>
          <div
            className={css({
              width: "25%",
              background: `linear-gradient(90deg, ${theme.colors.backgroundSecondary} 0%, ${theme.colors.backgroundLightAccent} 100%)`,
              opacity: "0.5",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              order: "1",
            })}
          ></div>
          <div
            className={css({
              width: "35%",
              background: `linear-gradient(90deg, ${theme.colors.backgroundSecondary} 0%, ${theme.colors.backgroundLightAccent} 100%)`,
              opacity: "0.5",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              order: "1",
            })}
          ></div>
          <div
            className={css({
              width: "20%",
              background: `linear-gradient(90deg, ${theme.colors.backgroundSecondary} 0%, ${theme.colors.backgroundLightAccent} 100%)`,
              opacity: "0.5",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              order: "1",
            })}
          ></div>
          <div
            className={css({
              width: "80%",
              background:
                "linear-gradient(90deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              paddingRight: theme.sizing.scale600,
              paddingLeft: "10vw",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxHeight: "11%",
              order: "2",
              [theme.breakpoints.mediaQueries.medium]: {
                order: "0",
              },
              [theme.breakpoints.mediaQueries.small]: {
                width: "100%",
              },
            })}
          >
            <HeadingLarge
              color="white"
              $style={{
                fontWeight: "300",
                [theme.breakpoints.mediaQueries.medium]: {
                  ...theme.typography.HeadingSmall,
                },
              }}
            >
              <FormattedContentItem
                scope="CaseStudies"
                accessor={`singleCaseStudies.${slug}.mockups.${index}.title`}
              />
            </HeadingLarge>
            <img
              alt="Shine"
              src={shine}
              className={css({
                height: "60px",
                width: "60px",
                [theme.breakpoints.mediaQueries.medium]: {
                  height: "30px",
                  width: "30px",
                },
              })}
            />
          </div>

          <div
            className={css({
              width: "30%",
              background: `linear-gradient(90deg, ${theme.colors.backgroundSecondary} 0%, ${theme.colors.backgroundLightAccent} 100%)`,
              opacity: "0.5",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              order: "3",
            })}
          ></div>
          <div
            className={css({
              width: "25%",
              background: `linear-gradient(90deg, ${theme.colors.backgroundSecondary} 0%, ${theme.colors.backgroundLightAccent} 100%)`,
              opacity: "0.5",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              order: "3",
            })}
          ></div>
          <div
            className={css({
              width: "20%",
              background: `linear-gradient(90deg, ${theme.colors.backgroundSecondary} 0%, ${theme.colors.backgroundLightAccent} 100%)`,
              opacity: "0.5",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              order: "3",
            })}
          ></div>

          <div
            className={css({
              width: "35%",
              background: `linear-gradient(90deg, ${theme.colors.backgroundSecondary} 0%, ${theme.colors.backgroundLightAccent} 100%)`,
              opacity: "0.5",
              flexGrow: "1",
              marginTop: theme.sizing.scale100,
              order: "3",
            })}
          ></div>
        </div>
        <Spring
          key={currentImage}
          config={{ tension: 120, friction: 14, duration: 200 }}
          from={{
            opacity: 0.9,
            top: 10,
          }}
          to={{ opacity: 1, top: 0 }}
        >
          {({ top, ...style }) => {
            return (
              <div
                className={css({
                  position: "relative",
                  top: `-${theme.sizing.scale500}`,
                  left: "50%",
                  overflow: "hidden",
                  pointerEvents: "none",
                  zIndex: "4",
                  transform: `translateX(-50%)`,
                  height: `calc(100% + 24px)`,
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "90vw",
                })}
              >
                <div
                  className={css({
                    width: maskHeight
                      ? `${Masks[
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .mockups[index].mask
                        ].widthMod * maskHeight}px`
                      : `auto`,
                    position: "absolute",
                    top: "0px",
                    bottom: "0px",
                    overflow: "hidden",
                    pointerEvents: "none",
                    zIndex: "4",
                    [theme.breakpoints.mediaQueries.medium]: {
                      top: "80px",
                    },
                  })}
                >
                  <img
                    alt="Device mask"
                    ref={maskRef}
                    onLoad={() =>
                      setTimeout(
                        () => setMaskHeight(maskRef.current.height),
                        100
                      )
                    }
                    src={
                      Masks[
                        ContentObject.CaseStudies.singleCaseStudies[slug]
                          .mockups[index].mask
                      ][
                        useDarkTheme
                          ? "Dark"
                          : ContentObject.CaseStudies.singleCaseStudies[slug]
                              .mockups[index].maskVariant
                      ]
                    }
                    height="100%"
                    className={css({
                      zIndex: "6",
                      position: "relative",
                      maxWidth: "90vw",
                    })}
                  />
                  <div
                    className={css({
                      position: "absolute",
                      overflow: "hidden",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      borderBottomRightRadius: "15px",
                      borderBottomLeftRadius: "15px",
                      zIndex: "5",
                      top:
                        Masks[
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .mockups[index].mask
                        ].inset.top,
                      left:
                        Masks[
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .mockups[index].mask
                        ].inset.left,
                      right:
                        Masks[
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .mockups[index].mask
                        ].inset.right,
                      bottom:
                        Masks[
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .mockups[index].mask
                        ].inset.bottom,
                      transform: `translateX(${top}px)`,
                      ...style,
                    })}
                  >
                    <AspectRatioBox
                      aspectRatio={
                        Masks[
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .mockups[index].mask
                        ].ratio
                      }
                      height={"100%"}
                      overflow="hidden"
                      backgroundColor="white"
                    >
                      {ContentObject.CaseStudies.singleCaseStudies[slug]
                        .mockups[index].items[currentImage].isSvg ? (
                        <AspectRatioBoxBody
                          as="img"
                          src={
                            SvgImages[
                              ContentObject.CaseStudies.singleCaseStudies[slug]
                                .mockups[index].items[currentImage].image
                            ]
                          }
                        />
                      ) : (
                        <AspectRatioBoxBody
                          as={() => (
                            <Img
                              src={`case-studies/${slug}/${ContentObject.CaseStudies.singleCaseStudies[slug].mockups[index].items[currentImage].image}`}
                              objectPosition={
                                Masks[
                                  ContentObject.CaseStudies.singleCaseStudies[
                                    slug
                                  ].mockups[index].mask
                                ].objectPosition || "left"
                              }
                            />
                          )}
                        />
                      )}
                    </AspectRatioBox>
                  </div>
                </div>
              </div>
            );
          }}
        </Spring>
      </div>
    );
};

MockupsItem.propTypes = {
  slug: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

const Mockups = ({ slug }) => {
  const [css] = useStyletron();

  return (
    <article
      data-csweb={`case-study-${slug}-mockups`}
      className={css({
        overflow: "hidden",
      })}
    >
      {ContentObject.CaseStudies.singleCaseStudies[slug].mockups &&
        ContentObject.CaseStudies.singleCaseStudies[
          slug
        ].mockups.map((item, index) => (
          <MockupsItem key={index} index={index} slug={slug} />
        ))}
    </article>
  );
};

Mockups.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Mockups;
