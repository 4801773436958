import PropTypes from "prop-types";
import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { Grid, Cell, Button } from "../../components/ui";
import {
  FormattedContentItem,
  ContentObject,
  ContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import { LabelSmall, DisplayXSmall, HeadingSmall } from "baseui/typography";

import chevronRight from "../../assets/images/chevron-right.svg";

const Link = ({ slug }) => {
  const [css, theme] = useStyletron();

  const caseStudies = Object.keys(ContentObject.CaseStudies.singleCaseStudies);
  const currentIndex = caseStudies.indexOf(slug);
  let nextIndex = currentIndex + 1;

  if (nextIndex >= ContentObject.CaseStudies.items.length) {
    nextIndex = 0;
  }

  return (
    <article data-csweb={`case-study-${slug}-link`}>
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale100,
              paddingBottom: theme.sizing.scale1200,
              position: "relative",
              zIndex: "2",
            })}
          >
            <LabelSmall
              $style={{
                textAlign: "center",
                textTransform: "uppercase",
                letterSpacing: "3px",
              }}
            >
              <FormattedContentItem scope="CaseStudies" accessor="next" />
            </LabelSmall>
            <GatsbyLink
              to={`/case-studies/${ContentObject.CaseStudies.items[nextIndex].slug}`}
              className={css({
                paddingTop: theme.sizing.scale800,
                marginBottom: theme.sizing.scale2400,
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "center",
                position: "relative",
                justifyContent: "center",
                textDecoration: "none",
                cursor: "pointer",
                ":hover [data-csweb=typo-case-study-subtitle]::after": {
                  width: "100%",
                },
                ":hover [data-csweb=bg-main]": {
                  transform: `translateX(70px)`,
                },
                ":hover [data-csweb=bg-main] span": {
                  transform: `translateX(50px})`,
                },
                ":hover [data-csweb=bg-hover]": {
                  transform: "translateX(0px)",
                },
                ":hover [data-csweb=bg-hover] span": {
                  transform: "translateX(0px)",
                },
              })}
            >
              <DisplayXSmall
                data-csweb="typo-case-study-subtitle"
                $style={{
                  display: "block",
                  fontWeight: "300",
                  paddingTop: theme.sizing.scale600,
                  paddingBottom: theme.sizing.scale500,
                  position: "relative",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "85%",
                  "::before": {
                    content: "''",
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    height: "1px",
                    background: theme.colors.backgroundLightAccent,
                  },
                  "::after": {
                    content: "''",
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    height: "1px",
                    width: "0%",
                    background: theme.colors.contentAccent,
                    transitionProperty: "all",
                    transitionDuration: "300ms",
                    transitionTimingFunction: "ease-in-out",
                  },
                  [theme.breakpoints.mediaQueries.medium]: {
                    ...theme.typography.DisplayXSmall,
                    whiteSpace: "normal",
                    textAlign: "center",
                    overflow: "visible",
                    "::before": {
                      display: "none",
                    },
                    "::after": {
                      display: "none",
                    },
                  },
                }}
              >
                <ContentItem
                  scope="CaseStudies"
                  accessor={`items.${nextIndex}.lead`}
                />
              </DisplayXSmall>
              <HeadingSmall
                data-csweb="typo-case-study-title"
                $style={{
                  display: "block",
                  fontWeight: "600",
                  fontSize: "30px",
                  marginBottom: theme.sizing.scale500,
                  marginTop: theme.sizing.scale600,
                  position: "relative",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  lineHeight: "1.5",
                  overflow: "hidden",
                  [theme.breakpoints.mediaQueries.medium]: {
                    whiteSpace: "normal",
                    textAlign: "center",
                    overflow: "visible",
                    "::before": {
                      display: "none",
                    },
                    "::after": {
                      display: "none",
                    },
                  },
                }}
              >
                <ContentItem
                  scope="CaseStudies"
                  accessor={`singleCaseStudies.${ContentObject.CaseStudies.items[nextIndex].slug}.name`}
                />
              </HeadingSmall>
              <Button
                data-csweb="ui-case-study-circular"
                kind="circle"
                className={css({
                  position: "absolute !important",
                  right: "0px",
                  top: "36%",
                  [theme.breakpoints.mediaQueries.large]: {
                    right: "0px",
                  },
                  [theme.breakpoints.mediaQueries.medium]: {
                    right: "50%",
                    bottom: "-90px",
                    transform: "translate(50%)",
                    top: "unset",
                  },
                  [theme.breakpoints.mediaQueries.small]: {
                    bottom: "-90px",
                    top: "unset",
                  },
                })}
                radius="70px"
              >
                <img alt="More" src={chevronRight} height="25px" />
              </Button>
            </GatsbyLink>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

Link.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Link;
