import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";

import { Grid, Cell, Img } from "../../components/ui";
import {
  ContentItem,
  FormattedContentItem,
  ContentObject,
} from "../../components/content";
import { useTheme } from "../../contexts/theme";

import { useStyletron } from "baseui";
import {
  LabelXSmall,
  LabelSmall,
  DisplayLarge,
  ParagraphMedium,
} from "baseui/typography";

import { AspectRatioBox, AspectRatioBoxBody } from "baseui/aspect-ratio-box";

import { Spring } from "react-spring/renderprops";

import dotGrid from "../../assets/images/dot-grid-light.svg";
import bgPath from "../../assets/images/bg-path.svg";
import bgPathPrimary from "../../assets/images/bg-path.primary.svg";

import Logo from "../../assets/images/case-studies/logo";
import Masks from "../../assets/images/masks";

const Hero = ({ slug }) => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();
  const maskRef = useRef(null);
  const [maskHeight, setMaskHeight] = useState();

  useEffect(() => {
    if (maskRef && maskRef.current && maskRef.current.complete)
      setMaskHeight(maskRef.current.height);
  }, []);

  return (
    <article
      data-csweb={`case-study-${slug}-hero`}
      className={css({
        minHeight: "100vh",
        boxSizing: "border-box",
        overflow: "hidden",
        display: "flex",
        alignItems: "stretch",
        backgroundColor: useDarkTheme
          ? theme.colors.backgroundPrimary
          : theme.colors.primary,
        paddingTop: theme.sizing.scale4800,
        [theme.breakpoints.mediaQueries.large]: {
          paddingTop: theme.sizing.scale4800,
        },
        [theme.breakpoints.mediaQueries.medium]: {
          minHeight: "auto",
          paddingTop: theme.sizing.scale3200,
        },
      })}
    >
      <div
        className={css({
          width: "100%",
          position: "relative",
          ":hover [data-csweb=bg-path]": {
            transform: "scaleY(1.6)",
          },
        })}
      >
        <img
          alt="Dot grid background"
          className={css({
            position: "absolute",
            bottom: "20%",
            left: "10%",
            zIndex: "0",
            pointerEvents: "none",
          })}
          src={dotGrid}
        />
        {ContentObject.CaseStudies.singleCaseStudies[slug].heroPath && (
          <img
            alt="Single path background"
            data-csweb="bg-path"
            className={css({
              position: "absolute",
              top: "25%",
              left: "-5%",
              right: "-10%",
              zIndex: "1",
              pointerEvents: "none",
              transitionProperty: "all",
              transitionDuration: "400ms",
              transitionTimingFunction: "ease-in-out",
            })}
            src={useDarkTheme ? bgPathPrimary : bgPath}
          />
        )}
        <div
          className={css({
            position: "relative",
            zIndex: "4",
            height: "100%",
          })}
        >
          <Grid
            $style={{
              height: "100%",
            }}
          >
            <Cell skip={[0, 0, 1]} span={[4, 5, 5]}>
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                })}
              >
                <div
                  className={css({
                    marginTop: "auto",
                    marginBottom: "auto",
                  })}
                >
                  <DisplayLarge
                    $style={{
                      marginBottom: theme.sizing.scale1200,
                      color: theme.colors.white,
                      fontWeight: "600",
                      fontSize: "78px",
                      lineHeight: "86px",
                      [theme.breakpoints.mediaQueries.medium]: {
                        ...theme.typography.DisplayMedium,
                        fontWeight: "bold",
                      },
                      [theme.breakpoints.mediaQueries.small]: {
                        ...theme.typography.DisplaySmall,
                      },
                    }}
                  >
                    <FormattedContentItem
                      scope="CaseStudies"
                      accessor={`singleCaseStudies.${slug}.title`}
                    />
                  </DisplayLarge>
                  <ParagraphMedium
                    $style={{
                      marginTop: theme.sizing.scale200,
                      marginBottom: theme.sizing.scale700,
                    }}
                    color="white"
                  >
                    <ContentItem
                      scope="CaseStudies"
                      accessor={`singleCaseStudies.${slug}.caption`}
                    />
                  </ParagraphMedium>
                  <LabelSmall
                    $style={{
                      marginTop: theme.sizing.scale200,
                      paddingBottom: theme.sizing.scale1200,
                      marginBottom: theme.sizing.scale600,
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    color="white"
                  >
                    <a
                      className={css({
                        textDecoration: "none",
                        color: "white",
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                        ":hover": {
                          opacity: 0.8,
                        },
                      })}
                      href={
                        ContentObject.CaseStudies.singleCaseStudies[slug].url
                      }
                      title={
                        ContentObject.CaseStudies.singleCaseStudies[slug].name
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ContentItem
                        scope="CaseStudies"
                        accessor={`singleCaseStudies.${slug}.link`}
                      />
                    </a>
                  </LabelSmall>
                </div>
                <div
                  className={css({
                    display: "flex",
                    alignItems: "flex-end",
                    marginBottom: theme.sizing.scale1600,
                  })}
                >
                  {ContentObject.CaseStudies.singleCaseStudies[slug].logo && (
                    <a
                      className={css({
                        textDecoration: "none",
                        color: "white",
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                        lineHeight: 1,
                        ":hover": {
                          opacity: 0.8,
                        },
                      })}
                      href={
                        ContentObject.CaseStudies.singleCaseStudies[slug].url
                      }
                      title={
                        ContentObject.CaseStudies.singleCaseStudies[slug].name
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt={
                          ContentObject.CaseStudies.singleCaseStudies[slug].name
                        }
                        src={
                          Logo[
                            ContentObject.CaseStudies.singleCaseStudies[slug]
                              .logo
                          ]
                        }
                        height="38px"
                        width="150px"
                        className={css({
                          objectFit: "contain",
                          objectPosition: "bottom",
                          [theme.breakpoints.mediaQueries.small]: {
                            marginRight: theme.sizing.scale0,
                            marginTop: theme.sizing.scale600,
                            height: "26px",
                          },
                        })}
                      />
                    </a>
                  )}
                  {ContentObject.CaseStudies.singleCaseStudies[slug]
                    .partnership && (
                    <>
                      <LabelXSmall
                        $style={{
                          fontSize: "10px",
                          textTransform: "uppercase",
                          fontWeight: "700",
                          letterSpacing: "2px",
                          marginRight: theme.sizing.scale750,
                          marginLeft: theme.sizing.scale1400,
                          lineHeight: "14px",
                          color: theme.colors.white,
                          textAlign: "right",
                          [theme.breakpoints.mediaQueries.small]: {
                            marginRight: theme.sizing.scale200,
                            marginLeft: theme.sizing.scale700,
                          },
                        }}
                      >
                        {
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .partnership.label
                        }
                      </LabelXSmall>
                      <a
                        className={css({
                          textDecoration: "none",
                          color: "white",
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionTimingFunction: "ease-in-out",
                          lineHeight: 1,
                          ":hover": {
                            opacity: 0.8,
                          },
                          [theme.breakpoints.mediaQueries.small]: {
                            marginLeft: theme.sizing.scale0,
                          },
                        })}
                        href={
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .partnership.url
                        }
                        title={
                          ContentObject.CaseStudies.singleCaseStudies[slug]
                            .partnership.name
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt={
                            ContentObject.CaseStudies.singleCaseStudies[slug]
                              .partnership.name
                          }
                          src={
                            Logo[
                              ContentObject.CaseStudies.singleCaseStudies[slug]
                                .partnership.logo
                            ]
                          }
                          className={css({
                            height: "30px",
                            width: "140px",
                            objectFit: "contain",
                            objectPosition: "bottom left",
                            [theme.breakpoints.mediaQueries.small]: {
                              marginLeft: theme.sizing.scale0,
                              height: "18px",
                              width: "90px",
                            },
                          })}
                        />
                      </a>
                    </>
                  )}
                </div>
              </div>
            </Cell>
            <Cell span={[0, 3, 5]} align="stretch">
              <Spring
                config={{ duration: 250 }}
                from={{ opacity: 0, right: 200 }}
                to={{ opacity: 1, right: 0 }}
              >
                {({ right, ...style }) => {
                  return (
                    <div
                      className={css({
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        bottom: "0px",
                        transform: `translateX(${right}px)`,
                        [theme.breakpoints.mediaQueries.small]: {
                          display: "none",
                        },
                        ...style,
                      })}
                    >
                      <div
                        className={css({
                          position: "absolute",
                          width: maskHeight
                            ? `${Masks[
                                ContentObject.CaseStudies.singleCaseStudies[
                                  slug
                                ].heroMask
                              ].widthMod * maskHeight}px`
                            : `auto`,
                          top: "0px",
                          ...(Masks[
                            ContentObject.CaseStudies.singleCaseStudies[slug]
                              .heroMask
                          ].align === "left"
                            ? { left: "10px" }
                            : { right: "10px" }),
                          overflow: "hidden",
                          pointerEvents: "none",
                          zIndex: "4",
                          bottom: Masks[
                            ContentObject.CaseStudies.singleCaseStudies[slug]
                              .heroMask
                          ].offset
                            ? Masks[
                                ContentObject.CaseStudies.singleCaseStudies[
                                  slug
                                ].heroMask
                              ].offset.bottom
                            : "-50px",
                          [theme.breakpoints.mediaQueries.large]: {
                            ...(Masks[
                              ContentObject.CaseStudies.singleCaseStudies[slug]
                                .heroMask
                            ].align === "left"
                              ? { left: "-80px" }
                              : { left: "10px", right: "unset" }),
                          },
                        })}
                      >
                        <img
                          alt="Device mask"
                          ref={maskRef}
                          onLoadedData={() =>
                            setMaskHeight(maskRef.current.height)
                          }
                          src={
                            Masks[
                              ContentObject.CaseStudies.singleCaseStudies[slug]
                                .heroMask
                            ][
                              useDarkTheme
                                ? "Dark"
                                : ContentObject.CaseStudies.singleCaseStudies[
                                    slug
                                  ].heroMaskVariant
                            ]
                          }
                          height="100%"
                          className={css({
                            zIndex: "6",
                            position: "relative",
                            display: "block",
                          })}
                        />
                        <div
                          className={css({
                            position: "absolute",
                            overflow: "hidden",
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                            borderBottomRightRadius: "15px",
                            borderBottomLeftRadius: "15px",
                            zIndex: "5",
                            top:
                              Masks[
                                ContentObject.CaseStudies.singleCaseStudies[
                                  slug
                                ].heroMask
                              ].inset.top,
                            left:
                              Masks[
                                ContentObject.CaseStudies.singleCaseStudies[
                                  slug
                                ].heroMask
                              ].inset.left,
                            right:
                              Masks[
                                ContentObject.CaseStudies.singleCaseStudies[
                                  slug
                                ].heroMask
                              ].inset.right,
                            bottom:
                              Masks[
                                ContentObject.CaseStudies.singleCaseStudies[
                                  slug
                                ].heroMask
                              ].inset.bottom,
                          })}
                        >
                          <AspectRatioBox
                            aspectRatio={
                              Masks[
                                ContentObject.CaseStudies.singleCaseStudies[
                                  slug
                                ].heroMask
                              ].ratio
                            }
                            overflow="hidden"
                          >
                            <AspectRatioBoxBody
                              as={() => (
                                <Img
                                  src={`case-studies/${slug}/${ContentObject.CaseStudies.singleCaseStudies[slug].heroMaskImage}`}
                                  objectPosition={
                                    Masks[
                                      ContentObject.CaseStudies
                                        .singleCaseStudies[slug].heroMask
                                    ].objectPosition || "left"
                                  }
                                />
                              )}
                              maxHeight="100%"
                              $style={{
                                borderTopLeftRadius: "15px",
                                borderTopRightRadius: "15px",
                                borderBottomRightRadius: "15px",
                                borderBottomLeftRadius: "15px",
                                transform: "translateY(-50%)",
                                top: "50%",
                              }}
                            />
                          </AspectRatioBox>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Spring>
            </Cell>
          </Grid>
        </div>
      </div>
    </article>
  );
};

Hero.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Hero;
